import ListItem from "./ListItem";
function NewOrderList(props) {
  const listData =
    props.items.length > 0 ? (
      props.items.map((item, index) => {
        // hideErr = { props.hideErr }
        return (
          <ListItem
            refreshApi={props.refreshApi}
            showErr={props.showErr}
            approved={props.approved}
            type="new"
            item={item}
            key={index}
          />
        );
      })
    ) : (
      <span>لا توجد طلبات حتى الان</span>
    );
  return (
    <div className="data-list new-orders">
      <h2>الطلبات الجديدة</h2>
      <ul>{listData}</ul>
    </div>
  );
}
export default NewOrderList;
