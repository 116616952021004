import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import "./App.css";
import InProgressList from "./components/InProgressList";
import NewOrderList from "./components/NewOrderList";
import useOrderData from "./hooks/useOrderData";

const App = () => {
  const {
    newOrders,
    inProgress,
    showAlert,
    errMsg,
    fillData,
    deliveredItem,
    approvedItem,
    showErr,
    hideErr,
    setShowAlert,
  } = useOrderData();

  return (
    <div className="App">
      <div className="App-wrapper">
        <Container fluid>
          <Row className="relative" key={Math.random()}>
            <Col sm={6} xs={12}>
              <NewOrderList
                refreshApi={() => fillData()}
                showErr={showErr}
                hideErr={hideErr}
                approved={approvedItem}
                items={newOrders}
              />
            </Col>
            <div className="v-line"></div>
            <Col sm={6} xs={12}>
              <InProgressList
                showErr={showErr}
                hideErr={hideErr}
                delivered={deliveredItem}
                items={inProgress}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {errMsg && showAlert && (
        <Alert
          show={showAlert}
          variant="danger"
          dismissible
          onClose={() => setShowAlert(false)}
        >
          <Alert.Link href="#">{errMsg}</Alert.Link>
        </Alert>
      )}
    </div>
  );
};

export default App;
