import {useState,useEffect} from 'react'
import ListItem from './ListItem'
function InProgressList(props) {
    const [progressData,setProgressData]=useState('')
    const [loading,setLoading]=useState(true)
    useEffect(() => {
        setProgressData(props.items)
       
    })
  
    const listData = progressData.length > 0 ? progressData.map((item, index) => {
        return (
            <ListItem showErr={props.showErr} hideErr={props.hideErr} delivered={props.delivered} item={item} key={index} />
        )
    }) : (<span>لا توجد طلبات حتى الان</span>)
    return (

        <div className='data-list in-progress'>
            <h2>
                طلبات قيد التنفيذ
            </h2>
            <ul>
                {listData}
            </ul>
        </div>
    )
}
export default InProgressList